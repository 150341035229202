@import '_variables';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;700&display=swap');

html,
body {
    background-color: $background-color;
    color: $primary-color;
    padding: 0;
    margin: 0;
    font-family: $font;
    font-weight: 400;
}

::selection {
  background: rgba($selection-color, .7);
  color: $primary-color;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}
